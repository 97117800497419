@import '../abstracts/mixins';

.pageNotFound {
  text-align: center;
  padding-top: 30px;
  min-height: 350px;
  min-width: 100vw;
  background: -moz-linear-gradient(45deg, #b088fa, #5a4483, #443461);
  background: -webkit-linear-gradient(45deg, #b088fa, #5a4483, #443461);
  background: linear-gradient(45deg, #b088fa, #5a4483, #443461);
  -webkit-animation: AnimateBackground 45s ease infinite;
  -moz-animation: AnimateBackground 45s ease infinite;
  animation: AnimateBackground 45s ease infinite;
  background-size: 200% 200%;
  padding-left: 0;
  padding-right: 0;
  height: 800px;
  width: 100%;
  @include media(tablet) {
    height: 700px;
  }
  @include media(mobile) {
    height: 530px;
  }
  h1 {
    @include page-header();
    font-size: 60px;
    margin-top: 0;
    margin-bottom: 0;
  }
  h3,
  h3 a {
    @include page-subheader();
    text-decoration: underline;
    cursor: pointer;
  }
  .houseAndLawnContainer {
    height: 600px;
    position: relative;
    @include media(tablet) {
      height: 520px;
      position: relative;
    }
    @include media(mobile) {
      height: 310px;
    }
    .lawnContainer {
      position: absolute;
      left: 0;
      width: 100%;
      overflow: hidden;
      top: 305px;
      height: calc(100% - 305px);
      @include media(tablet) {
        top: 240px;
        height: calc(100% - 240px);
      }
      @include media(mobile) {
        top: 120px;
        height: calc(100% - 120px);
      }
      .lawnInner {
        width: 100%;
        height: 100%;
        @include media(tablet) {
          width: 160%;
          left: -30%;
          top: 0;
          position: absolute;
        }
        @include media(mobile) {
          width: 160%;
          left: -30%;
          top: 0;
          position: absolute;
        }
      }
    }
    .houseIconContainer {
      position: absolute;
      width: 100%;
      top: 130px;
      @include media(tablet) {
        top: 120px;
      }
      @include media(mobile) {
        top: 49px;
      }
      .houseIcon {
        position: relative;
        margin: 0 auto;
        width: 801px;
        height: 439px;
        @include media(tablet) {
          width: 600px;
          height: 329px;
        }
        @include media(mobile) {
          width: 360px;
          height: 197px;
        }
      }
    }
  }
}
